.App {
  text-align: center;
  width: 100%;
  height: 100vh;
}

.pokemonCardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  place-items: center;
  gap: 20px;
  margin-top: 80px;
}

.btn {
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.btn button {
  padding: 13px 32px;
  background-color: #5257a3;
  border: none;
  box-shadow: 2px 8px 21px -2px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
}

.btn button:hover {
  transform: translateY(5px);
  box-shadow: none;
}
