nav {
  background-color: #d69a3f;
  color: white;
  height: 65px;
  font-weight: 600;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
