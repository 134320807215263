.card {
  width: 290px;
  box-shadow: 2px 8px 21px -2px;
  border-radius: 10px;
  background-color: white;
}

.cardName {
  padding: 0px;
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 0px;
}

.cardInfo {
  text-align: center;
}
